import image00 from "./image-00.jpg";
import image01 from "./image-01.jpg";
import image02 from "./image-02.jpg";
import image03 from "./image-03.jpg";
import image04 from "./image-04.jpg";
import image05 from "./image-05.jpg";
import image06 from "./image-06.jpg";
import image07 from "./image-07.jpg";
import image08 from "./image-08.jpg";
import image09 from "./image-09.jpg";
import image10 from "./image-10.jpg";
import image11 from "./image-11.jpg";
import image12 from "./image-12.jpg";
import image13 from "./image-13.jpg";
import image14 from "./image-14.jpg";
import image15 from "./image-15.jpg";
import image16 from "./image-16.jpg";
import image17 from "./image-17.jpg";
import image18 from "./image-18.jpg";
import image19 from "./image-19.jpg";
import image20 from "./image-20.jpg";
import image21 from "./image-21.jpg";
import image22 from "./image-22.jpg";
import image23 from "./image-23.jpg";
import image24 from "./image-24.jpg";
import image25 from "./image-25.jpg";
import image26 from "./image-26.jpg";
import image27 from "./image-27.jpg";
import image28 from "./image-28.jpg";
import image29 from "./image-29.jpg";
import image31 from "./image-31.jpg";
import image32 from "./image-32.jpg";
import image33 from "./image-33.jpg";
import image34 from "./image-34.jpg";
import image35 from "./image-35.jpg";
import image36 from "./image-36.jpg";
import image37 from "./image-37.jpg";
import image38 from "./image-38.jpg";
import image39 from "./image-39.jpg";
import image40 from "./image-40.jpg";

export { default as image00 } from "./image-00.jpg";
export { default as image01 } from "./image-01.jpg";
export { default as image02 } from "./image-02.jpg";
export { default as image03 } from "./image-03.jpg";
export { default as image04 } from "./image-04.jpg";
export { default as image05 } from "./image-05.jpg";
export { default as image06 } from "./image-06.jpg";
export { default as image07 } from "./image-07.jpg";
export { default as image08 } from "./image-08.jpg";
export { default as image09 } from "./image-09.jpg";
export { default as image10 } from "./image-10.jpg";
export { default as image11 } from "./image-11.jpg";
export { default as image12 } from "./image-12.jpg";
export { default as image13 } from "./image-13.jpg";
export { default as image14 } from "./image-14.jpg";
export { default as image15 } from "./image-15.jpg";
export { default as image16 } from "./image-16.jpg";
export { default as image17 } from "./image-17.jpg";
export { default as image18 } from "./image-18.jpg";
export { default as image19 } from "./image-19.jpg";
export { default as image20 } from "./image-20.jpg";
export { default as image21 } from "./image-21.jpg";
export { default as image22 } from "./image-22.jpg";
export { default as image23 } from "./image-23.jpg";
export { default as image24 } from "./image-24.jpg";
export { default as image25 } from "./image-25.jpg";
export { default as image26 } from "./image-26.jpg";
export { default as image27 } from "./image-27.jpg";
export { default as image28 } from "./image-28.jpg";
export { default as image29 } from "./image-29.jpg";
export { default as image31 } from "./image-31.jpg";
export { default as image32 } from "./image-32.jpg";
export { default as image33 } from "./image-33.jpg";
export { default as image34 } from "./image-34.jpg";
export { default as image35 } from "./image-35.jpg";
export { default as image36 } from "./image-36.jpg";
export { default as image37 } from "./image-37.jpg";
export { default as image38 } from "./image-38.jpg";
export { default as image39 } from "./image-39.jpg";
export { default as image40 } from "./image-40.jpg";

export default [
  image00,
  image01,
  image02,
  image03,
  image04,
  image05,
  image06,
  image07,
  image08,
  image09,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  image25,
  image26,
  image27,
  image28,
  image29,
  image31,
  image32,
  image33,
  image34,
  image35,
  image36,
  image37,
  image38,
  image39,
  image40,
];
